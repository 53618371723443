<template>
  <div class="wrapper">
    <App-header />
    <el-row>
      <el-col :span="24">
        <el-row class="nav">
          <el-col :span="24">
            <nav>
              <a class="categorys_title"><strong>分类：</strong></a>
              <div class="nav_item">
                <a v-for="(item, index) in categorys" :key="index" :id="item.id"
                  :class="actives.categorys == item.id ? 'active' : ''" @click="changeCondition('categorys', item.id)">{{
                    item.name }}</a>
              </div>
            </nav>
          </el-col>
          <el-col :span="24">
            <nav>
              <a class="categorys_title"><strong>颜色：</strong></a>
              <div class="nav_item">
                <a v-for="(item, index) in colors" :key="index" :id="item.id"
                  :class="actives.colors == item.id ? 'active' : ''" @click="changeCondition('colors', item.id)">{{
                    item.name }}</a>
              </div>
            </nav>
          </el-col>
          <el-col :span="24">
            <nav style="display: flex; justify-content: space-between">
              <div style="display: flex">
                <a class="categorys_title"><strong>方向：</strong></a>
                <div class="nav_item">
                  <a v-for="(item, index) in directions" :key="index" :id="item.id"
                    :class="actives.directions == item.id ? 'active' : ''"
                    @click="changeCondition('directions', item.id)">{{ item.name }}</a>
                </div>
                <a class="categorys_title" style="margin-left: 60px;"><strong>排序：</strong></a>
                <div class="nav_item">
                  <a v-for="(item, index) in sorts" :key="index" :id="item.id" class="sort"
                    :class="actives.sorts == item.id ? 'active' : ''" @click="changeCondition('sorts', item.id)">{{
                      item.name }}</a>
                </div>
              </div>
              <div>
                <a :href="teachLink" target="_blank">教学视频</a>
                <el-button type="primary" size="mini" @click="goCreator()">AI创作</el-button>
                <el-button type="primary" size="mini" @click="goVideoAI()">一键成片</el-button>
              </div>
            </nav>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <ul class="templates">
          <li v-for="(item, index) in templates" :key="index">
            <div class="imagebox">
              <img crossorigin="anonymous" :src="item.cover" loading="lazy"  />
              <div class="fake">
                <el-button @click="jumpPage(item)" type="primary">单条快剪</el-button>
                <el-button @click="jumpPage(item, 1)" type="primary">智能云剪</el-button>
              </div>
            </div>
            <h3 style="margin: 20px 0 10px 0">{{ item.name }}</h3>
            <div style="color: #888">编号：{{ 10000 + item.id }}</div>
          </li>
          <li class="hide"></li>
          <li class="hide"></li>
          <li class="hide"></li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<style>
.nav nav {
  display: flex;
  padding: 10px 0;
}

.nav .el-col:nth-child(-n + 3) nav {
  border-bottom: 1px dashed #424245;
}

.nav .el-col:last-child nav {
  border-bottom: none;
}

.nav a.active,
.nav a.sort.active,
.fake>a {
  color: #fff;
  background-color: #ff7800;
  border-color: #ff7800;
}

.nav_item {
  display: flex;
  flex-wrap: wrap;
}

.nav a,
.fake>a {
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease;
  color: #fff;
  margin: 5px;
  padding: 9px 15px;
  border-radius: 2.5px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
}

.nav a.sort {
  background: #3a3a3e;
  border-color: #424245;
}

.nav .categorys_title {
  display: flex;
  cursor: inherit;
  font-size: 16px;
  padding: 9px 4px 9px 0;
}

.templates {
  flex-wrap: wrap;
  text-align: center;
  padding: 20px 0 0 0;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  list-style: none;
  margin: 0;
}

.templates li {
  list-style: none;
  width: 19%;
  margin-bottom: 20px;
}

.templates li .imagebox {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.templates li .imagebox img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 4px;
}

.hide {
  visibility: hidden;
}

.fake {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease;
}

.fake>a {
  text-decoration: none;
}

.templates li .imagebox:hover .fake {
  opacity: 1;
  z-index: 9;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1550px) {
  .templates li {
    width: 24%;
  }
}
</style>

<script>
import { reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import config from "@/config";
import { useRouter } from "vue-router";
import AppHeader from "@/components/AppHeader";
import { store } from "@/libs/store";

export default {
  components: {
    AppHeader,
  },
  setup() {
    const router = useRouter();
    const {sign, uid, yanshi, shujun} = store.user
    const categorys = ref([]);
    const colors = ref([]);
    const directions = ref([]);
    const sorts = ref([]);
    const templates = ref([]);
    const actives = reactive({
      categorys: null,
      colors: null,
      directions: null,
      sorts: null,
    });
    var teachLink = 'https://g4-img-all.oss-cn-beijing.aliyuncs.com/video/50089839/c3520fa43c501d73b9454481af79cb08.mp4'
    const jumpPage = (item, mix = 0) => {
      let path = 'videodiy'
      if (mix == 1) path = 'videomix'
      let explorer = window.navigator.userAgent;
      if (explorer.indexOf("Edge/") != -1 || explorer.indexOf("Edg/") != -1) {
        router.push({ path: `/${path}/${item.id}`, query: { uid: sign, d: item.direction, mix } });
      } else {
        ElMessageBox.alert(
          `<div>树品快剪基于Edge浏览器开发，如果出现浏览器无法加载，请下载微软最新浏览器Edge。</div>
          <div>如果出现卡顿、崩溃等情况，请清理电脑内存或更换更高配置的电脑。</div>
          <a style='color: #ff7800;' href='https://www.microsoft.com/zh-cn/edge?r=1' target='_blank'>点击下载</a>`,
          "温馨提示",
          {
            showCancelButton: true,
            cancelButtonText: "取消",
            confirmButtonText: "确定进入",
            dangerouslyUseHTMLString: true,
          }
        )
          .then(() => {
            router.push({ path: `/${path}/${item.id}`, query: { uid: sign, d: item.direction, mix } });
          })
          .catch(() => { });
      }
    };
    const init = async () => {
      let res;
      try {
        res = await fetch(config.ServerURL + `/conditions?uid=${uid}`);
        res = await res.json();
      } catch (error) {
        ElMessage.error("加载模板筛选配置失败");
        return false;
      }
      if (res.message == "success") {
        categorys.value = res.conditions.categorys;
        categorys.value.unshift({ id: 0, name: "全部分类" });
        actives.categorys = 0;

        colors.value = res.conditions.colors;
        colors.value.unshift({ id: 0, name: "全部" });
        actives.colors = 0;

        directions.value = res.conditions.directions;
        directions.value.unshift({ id: 0, name: "全部" });
        actives.directions = directions.value[0].id;

        sorts.value = res.conditions.sorts;
        actives.sorts = sorts.value[0].id;
      } else {
        ElMessage.error(res.message);
        return false;
      }
    };
    const changeCondition = async (ref, id) => {
      actives[ref] = id;
      let res;
      try {
        res = await fetch(config.ServerURL + `/templates?uid=${uid}`, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            category: actives.categorys,
            color: actives.colors,
            direction: actives.directions,
            sort: actives.sorts,
          }),
        });
        res = await res.json();
      } catch (error) {
        ElMessage.error("获取模板列表失败");
        return false;
      }
      if (res.message == "success") {
        templates.value = res.templates;
      } else {
        ElMessage.error(res.message);
        return false;
      }
    };

    init().then(changeCondition);

    const goVideoAI = () => {
      router.push({ path: "/videoai", query: { uid: sign } });
    }
    const goCreator = () => {
      router.push({ path: "/creativeai", query: { uid: sign } });
    }

    return {
      categorys,
      yanshi,
      shujun,
      actives,
      colors,
      directions,
      sorts,
      templates,
      teachLink,
      changeCondition,
      jumpPage,
      goVideoAI,
      goCreator
    };
  },
};
</script>