<template>
  <router-view />
</template>

<style lang="scss">
body,
html,
#app {
  color: #fff;
  background: #2b2b2e;
  margin: 0;
  padding: 0;
}
* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, 0.4);
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, 0.4);
  }

  &:hover::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, 0.1);
  }
}
body {
  .el-switch.is-checked .el-switch__core,
  .el-radio__input.is-checked .el-radio__inner {
    background-color: #ff7800;
    border-color: #ff7800;
  }
  .el-button {
    &:focus,
    &:hover {
      color: #ff7800;
    }
    &.el-button--primary {
      color: #fff;
      background-color: #ff7800;
      border-color: #ff7800;
    }
    &.el-button--primary:focus,
    &.el-button--primary:hover {
      background: rgba(255, 120, 0, 0.8);
      border: 1px solid rgba(255, 120, 0, 0.9);
    }
    &.el-button--primary.is-disabled,
    &.el-button--primary.is-disabled:active,
    &.el-button--primary.is-disabled:focus,
    &.el-button--primary.is-disabled:hover {
      background-color: #e9974f;
      border-color: #e9974f;
    }
  }
  .el-upload-list__item:hover .el-upload-list__item-name,
  .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
  .el-radio__input.is-checked + .el-radio__label,
  .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item:not(.is-disabled):hover {
    color: #ff7800;
  }
  .el-form-item__label,
  .el-radio,
  .el-dialog__title,
  .el-dialog__headerbtn .el-dialog__close,
  .el-drawer__header,
  .el-drawer__close .el-icon .el-icon-close,
  .el-upload-list__item-name {
    color: #fff;
  }
  .el-drawer__close-btn:hover i,
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-input-number__decrease:hover,
  .el-input-number__increase:hover,
  .el-select-dropdown__item.selected,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #ff7800;
  }
  .el-slider__bar {
    background-color: #ff7800;
  }
  .el-slider__button {
    border: solid 2px #ff7800;
  }
  .el-card {
    border-color: #454545;
    box-shadow: none;
    background: #2b2b2e;
    color: #fff;
    margin-bottom: 20px;
    .el-card__header {
      background: #2b2b2e;
      color: #fff;
      border-color: #454545;
    }
  }
  .el-card.box-card {
    .el-card__body {
      padding: 10px 20px;
    }
    .el-form-item {
      margin-bottom: 5px;
    }
  }
  .el-card.card {
    .el-form-item {
      margin-bottom: 12px;
    }
  }
  .el-input__inner:focus,
  .el-input-number__decrease:hover
    ~ .el-input:not(.is-disabled)
    .el-input__inner,
  .el-select .el-input.is-focus .el-input__inner,
  .el-select .el-input__inner:focus,
  .el-input-number__increase:hover
    ~ .el-input:not(.is-disabled)
    .el-input__inner {
    border-color: #fff;
  }
  .el-dialog,
  .el-drawer {
    background: #2b2b2e;
  }
  .el-tabs--border-card {
    border-color: #454545;
    box-shadow: none;
    background: #2b2b2e;
  }
  .el-input__inner {
    border: 1px solid #424245;
    background: #424245;
    color: #fff;
  }
  .el-textarea__inner {
    border: 1px solid #424245;
    background: #424245;
    color: #fff;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wrapper {
  width: 1510px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1550px) {
  .wrapper {
    width: 100%;
    padding: 0 8vw;
  }
}
@media screen and (max-width: 1000px) {
  .wrapper {
    width: 100%;
    padding: 0 2vw;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    width: 700px;
  }
}
</style>
