import { createRouter, createWebHistory } from 'vue-router'
import TemplatesCategory from '../views/TemplatesCategory.vue'

const routes = [
  {
    path: '/',
    name: '模板列表',
    component: TemplatesCategory
  },
  {
    path: '/videoai',
    name: '一键成片',
    component: () => import('../views/VideoAI.vue')
  },
  {
    path: '/videoai/store',
    name: 'AI智能库',
    component: () => import('../views/VideoAIAuto.vue')
  },
  {
    path: '/videoai/auto/:taskid',
    name: 'AI全自动',
    component: () => import('../views/VideoAIAuto.vue')
  },
  {
    path: '/scriptai',
    name: 'AI脚本',
    component: () => import('../views/ScriptAI.vue')
  },
  {
    path: '/creativeai',
    name: 'AI创意',
    component: () => import('../views/CreativeAI.vue')
  },
  {
    path: '/articleai',
    name: 'AI文案',
    component: () => import('../views/ArticleAI.vue')
  },
  {
    path: '/scenarioai',
    name: 'AI剧本',
    component: () => import('../views/ScenarioAI.vue')
  },
  {
    path: '/videodiy/:tid',
    name: '视频编辑',
    component: () => import('../views/VideoDiy.vue')
  },
  {
    path: '/videomix/:tid',
    name: '智能混剪',
    component: () => import('../views/VideoMix.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
