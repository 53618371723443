import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'
import router from './router'
import { store } from './libs/store'

const app = createApp(App).use(router)
installElementPlus(app)

router.beforeEach((to) => {
    /* 路由发生变化修改页面title */
    if (to.name) {
        document.title = to.name + '-树品快剪'
    }
    const sign = to.query.uid;
    if (!sign) {
        alert("请登录！")
        return false;
    }
    const [uid, yanshi, shujun, version, domain, group] = window.atob(sign).split('@')
    store.initUser({ sign, uid, yanshi, shujun, version, domain, group })
})
app.mount('#app')